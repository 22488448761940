exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-irada-ai-js": () => import("./../../../src/pages/irada-ai.js" /* webpackChunkName: "component---src-pages-irada-ai-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-single-insight-js": () => import("./../../../src/templates/single-insight.js" /* webpackChunkName: "component---src-templates-single-insight-js" */),
  "component---src-templates-single-solution-js": () => import("./../../../src/templates/single-solution.js" /* webpackChunkName: "component---src-templates-single-solution-js" */)
}

